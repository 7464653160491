import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import clsx from 'clsx'

export default function BannerDefault({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefault" className="section--pb pt-6 sm:pt-11 section--with-bg bg-site-alt">
      <div className="container h-full">
        <div>
          <Breadcrumbs />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {fields?.title && (
            <div className="pt-4 sm:pt-7 lg:pl-16">
              {fields?.title && (
                <h1 className="flex max-sm:flex-col flex-wrap md:text-center py-2">
                  {fields?.title?.split(' | ').map((part, key) => (
                    <span
                      key={key}
                      className={clsx(
                        'text-3xl xs:text-4xl sm:text-5xl lg:text-6xl uppercase md:text-center font-light mx-1 lg:mx-2 drop-shadow text-white',
                        key !== 0 ? 'font-semibold' : ''
                      )}
                    >
                      {part}
                    </span>
                  ))}
                </h1>
              )}
            </div>
          )}
          {fields?.description && (
            <div className="pt-3 lg:pl-16">
              <Content className="content children-p:text-white">{fields?.description}</Content>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
