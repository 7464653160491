import type { Page_Flexcontent_Flex_Posts, Teamlid } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Arrow } from '~/components/elements/Arrow'
import { useState } from 'react'
import { Subtitle } from '~/components/elements/Subtitle'
import { Title } from '~/components/elements/Title'
import Content from '~/components/elements/Content'

export default function TeamHighlighted({ fields }: { fields: Page_Flexcontent_Flex_Posts }) {
  const [index, setIndex] = useState(0)
  const teammembers = fields.posts.edges as unknown as { node: Teamlid }[]

  return (
    <section data-component="TeamHighlighted" className="section section--with-bg bg-site-base">
      <div className="container flex flex-col gap-4 items-center">
        <Subtitle type="dark">{fields?.subtitle}</Subtitle>
        <Title type="dark" size="small">
          {fields?.title}
        </Title>

        <div className="flex flex-row mt-8 md:mt-16 gap-1 md:gap-8 items-center">
          <div
            className="h-full cursor-pointer hidden lg:block"
            onClick={() => {
              if (index === 0) {
                setIndex(teammembers.length - 1)
              } else {
                setIndex(index - 1)
              }
            }}
          >
            <Arrow orientation="left" color="white" hover="accent" />
          </div>
          <div
            key={index}
            className="max-lg:flex max-lg:flex-col lg:grid lg:min-h-[490px] xl:min-h-[530px] lg:grid-cols-2 gap-10 xl:gap-16"
          >
            <div className="flex flex-row gap-4 md:gap-8 items-center">
              <div
                className="h-full flex items-center cursor-pointer lg:hidden"
                onClick={() => {
                  if (index === 0) {
                    setIndex(teammembers.length - 1)
                  } else {
                    setIndex(index - 1)
                  }
                }}
              >
                <Arrow orientation="left" color="white" hover="accent" />
              </div>
              <div className="h-full w-full left-0 top-0 overflow-hidden rounded-[7px]">
                <LossePlaatjie
                  loading="eager"
                  className="w-full h-full object-cover rounded-[7px] animate-fade-left animate-ease-in-out"
                  src={teammembers[index].node.featuredImage.node}
                  maxwidth={530}
                />
              </div>
              <div
                className="h-full flex items-center cursor-pointer lg:hidden"
                onClick={() => {
                  if (index === teammembers.length - 1) {
                    setIndex(0)
                  } else {
                    setIndex(index + 1)
                  }
                }}
              >
                <Arrow color="white" hover="accent" />
              </div>
            </div>
            <div className="children:text-white flex flex-col animate-fade-left animate-ease-in-out animate-delay-75 lg:justify-center">
              <div className="font-semibold text-3xl">{teammembers[index].node.title}</div>
              <Content className="children-p:text-white mt-6 pb-8">{teammembers[index].node.recap.description}</Content>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="flex hover:text-site-accent transition-all duration-200 items-center gap-3 font-semibold mb-5"
                href={`mailto:${teammembers[index].node.recap.emailAddress}`}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_864_445)">
                    <path
                      d="M21 1.00031H3C2.20435 1.00031 1.44129 1.31638 0.87868 1.87898C0.31607 2.44159 0 3.20466 0 4.00031L0 23.0003H24V4.00031C24 3.20466 23.6839 2.44159 23.1213 1.87898C22.5587 1.31638 21.7956 1.00031 21 1.00031ZM3 3.00031H21C21.2652 3.00031 21.5196 3.10566 21.7071 3.2932C21.8946 3.48073 22 3.73509 22 4.00031V4.6673L14.122 12.5463C13.5584 13.1076 12.7954 13.4228 12 13.4228C11.2046 13.4228 10.4416 13.1076 9.878 12.5463L2 4.6673V4.00031C2 3.73509 2.10536 3.48073 2.29289 3.2932C2.48043 3.10566 2.73478 3.00031 3 3.00031ZM2 21.0003V7.50031L8.464 13.9603C9.40263 14.8966 10.6743 15.4223 12 15.4223C13.3257 15.4223 14.5974 14.8966 15.536 13.9603L22 7.50031V21.0003H2Z"
                      fill="#42BD3B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_864_445">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                {teammembers[index].node.recap.emailAddress}
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="flex hover:text-site-accent transition-all duration-200 items-center gap-3 font-semibold"
                href={`tel:${teammembers[index].node.recap.phoneNumber}`}
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_864_448)">
                    <path
                      d="M14.2002 16.261C11.2886 15.0282 8.97325 12.7075 7.7472 9.793L11.1472 6.387L4.8872 0.122L1.7162 3.292C1.1662 3.84514 0.731342 4.50186 0.436795 5.22415C0.142247 5.94645 -0.00614821 6.71998 0.000195101 7.5C0.000195101 14.748 9.25219 24 16.5002 24C17.2801 24.0068 18.0536 23.8586 18.7758 23.5641C19.498 23.2695 20.1545 22.8344 20.7072 22.284L23.8782 19.113L17.6132 12.848L14.2002 16.261ZM19.2922 20.87C18.925 21.234 18.489 21.5214 18.0097 21.7154C17.5304 21.9094 17.0172 22.0061 16.5002 22C10.2672 22 2.0002 13.733 2.0002 7.5C1.99431 6.98283 2.09116 6.46964 2.28514 5.99019C2.47911 5.51074 2.76635 5.07458 3.1302 4.707L4.8872 2.95L8.3242 6.387L5.38919 9.322L5.63419 9.936C6.35544 11.8653 7.48296 13.6171 8.94035 15.0725C10.3977 16.528 12.1509 17.6533 14.0812 18.372L14.6872 18.603L17.6132 15.676L21.0502 19.113L19.2922 20.87ZM14.0002 2V0C16.6515 0.0029116 19.1933 1.05742 21.068 2.93215C22.9428 4.80688 23.9973 7.34873 24.0002 10H22.0002C21.9978 7.879 21.1542 5.84555 19.6544 4.34578C18.1546 2.846 16.1212 2.00238 14.0002 2ZM14.0002 6V4C15.591 4.00159 17.1162 4.63424 18.2411 5.75911C19.366 6.88399 19.9986 8.40919 20.0002 10H18.0002C18.0002 8.93913 17.5788 7.92172 16.8286 7.17157C16.0785 6.42143 15.0611 6 14.0002 6Z"
                      fill="#42BD3B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_864_448">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {teammembers[index].node.recap.phoneNumber}
              </a>
            </div>
          </div>

          <div
            className="h-full cursor-pointer hidden lg:block"
            onClick={() => {
              if (index === teammembers.length - 1) {
                setIndex(0)
              } else {
                setIndex(index + 1)
              }
            }}
          >
            <Arrow color="white" hover="accent" />
          </div>
        </div>
      </div>
    </section>
  )
}
