import type { Page_Flexcontent_Flex_Banner, Vacatures } from '~/graphql/types'
import { type LoaderData, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import Breadcrumbs from '~/components/elements/Breadcrumbs'
import Links from '~/components/elements/Links'
import clsx from 'clsx'

export default function BannerVacancy({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const data = useLoaderData<LoaderData<Vacatures>>()

  return (
    <section className="relative section section--with-bg" data-component="BannerVacancy">
      <div className="container md:h-[400px] lg:h-[400px]">
        {fields?.image && (
          <div className="h-full w-full left-0 top-0 overflow-hidden absolute -z-10">
            <LossePlaatjie
              loading="eager"
              className={clsx(fields?.imagemobile && 'max-sm:hidden', ' w-full h-full object-cover')}
              src={fields.image}
              maxwidth={2000}
            />
            {fields?.imagemobile && (
              <LossePlaatjie
                loading="eager"
                className="sm:hidden w-full h-full object-cover"
                src={fields.imagemobile}
                maxwidth={640}
              />
            )}
          </div>
        )}
        <div className="absolute top-4 md:top-11">
          <Breadcrumbs />
        </div>
        <div className="flex flex-col max-md:pt-10 gap-10 w-full h-full justify-center md:px-16">
          {fields?.title && (
            <div className="flex flex-row">
              <h1 className="title--base text-4xl lg:text-6xl font-semibold !text-white">{fields.title}</h1>
            </div>
          )}
          {data?.page?.recap && (
            <div className="max-lg:w-full lg:w-[750px] text-white flex max-md:flex-col md:flex-row max-md:gap-2 md:gap-16">
              <div className="relative pl-9">
                <svg
                  className="absolute left-0 top-[2px]"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_881_1906)">
                    <path
                      d="M14.12 1.62653C13.4886 1.21759 12.7523 1 12 1C11.2477 1 10.5114 1.21759 9.88 1.62653L0 7.99953L4 10.5795V20.4135L4.293 20.7065C4.387 20.7995 6.647 22.9995 12 22.9995C17.353 22.9995 19.613 20.7995 19.707 20.7065L20 20.4135V10.5795L22 9.28953V19.9995H24V7.99953L14.12 1.62653ZM18 19.5155C16.1928 20.5977 14.1033 21.1145 12 20.9995C9.89685 21.1148 7.8073 20.5983 6 19.5165V11.8695L9.88 14.3695C10.5111 14.7794 11.2475 14.9976 12 14.9976C12.7525 14.9976 13.4889 14.7794 14.12 14.3695L18 11.8695V19.5155ZM13.036 12.6915C12.7277 12.8921 12.3678 12.9988 12 12.9988C11.6322 12.9988 11.2723 12.8921 10.964 12.6915L3.69 7.99953L10.964 3.30753C11.2723 3.10698 11.6322 3.00023 12 3.00023C12.3678 3.00023 12.7277 3.10698 13.036 3.30753L20.31 7.99953L13.036 12.6915Z"
                      fill="#42BD3B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_881_1906">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {data.page.recap.education}
              </div>
              <div className="relative pl-9">
                <svg
                  className="absolute left-0 top-[2px]"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_881_1908)">
                    <path
                      d="M11 22C8.82441 22 6.69767 21.3549 4.88873 20.1462C3.07979 18.9375 1.66989 17.2195 0.83733 15.2095C0.00476615 13.1995 -0.213071 10.9878 0.211367 8.85401C0.635804 6.72022 1.68345 4.76021 3.22183 3.22183C4.76021 1.68345 6.72022 0.635804 8.85401 0.211367C10.9878 -0.213071 13.1995 0.00476615 15.2095 0.83733C17.2195 1.66989 18.9375 3.07979 20.1462 4.88873C21.3549 6.69767 22 8.82441 22 11C21.9969 13.9164 20.8369 16.7125 18.7747 18.7747C16.7125 20.8369 13.9164 21.9969 11 22ZM11 1.83334C9.18701 1.83334 7.41473 2.37095 5.90728 3.3782C4.39983 4.38545 3.22491 5.81708 2.53111 7.49207C1.83731 9.16706 1.65578 11.0102 2.00947 12.7883C2.36317 14.5665 3.23621 16.1998 4.51819 17.4818C5.80017 18.7638 7.43352 19.6368 9.21168 19.9905C10.9898 20.3442 12.8329 20.1627 14.5079 19.4689C16.1829 18.7751 17.6146 17.6002 18.6218 16.0927C19.6291 14.5853 20.1667 12.813 20.1667 11C20.164 8.56967 19.1974 6.23965 17.4789 4.52114C15.7604 2.80264 13.4303 1.83601 11 1.83334Z"
                      fill="#42BD3B"
                    />
                    <path d="M7.28508 14.2982L6.3125 12.7435L10.0837 10.3812V5.5H11.917V11.3969L7.28508 14.2982Z" fill="#42BD3B" />
                  </g>
                  <defs>
                    <clipPath id="clip0_881_1908">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {data.page.recap.time}
              </div>
              <div className="relative pl-9">
                <svg
                  className="absolute left-1 top-[2px]"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_881_1912)">
                    <path
                      d="M16.4995 8.31413V5.5002C16.4995 4.15551 15.5567 2.94356 13.8448 2.08768C12.3349 1.33277 10.3479 0.916992 8.24978 0.916992C6.1514 0.916992 4.16431 1.33277 2.65448 2.08768C0.942725 2.94356 0 4.15551 0 5.5002V9.16671V12.8331V16.4996C0 17.8444 0.942725 19.0562 2.65454 19.9122C4.16437 20.6671 6.15146 21.0828 8.24984 21.0828C9.84835 21.0828 11.3722 20.8443 12.6887 20.3921C13.5587 20.8336 14.5422 21.0828 15.5828 21.0828C19.1213 21.0828 22.0001 18.204 22.0001 14.6656C22 11.4384 19.6053 8.76034 16.4995 8.31413ZM1.8335 12.1018C2.08353 12.2708 2.35709 12.4305 2.65454 12.5792C4.16437 13.3341 6.15152 13.7499 8.24984 13.7499C8.58072 13.7499 8.90966 13.739 9.23553 13.7184C9.18963 14.0276 9.16549 14.3438 9.16549 14.6656C9.16549 14.965 9.18657 15.2597 9.22648 15.5483C8.90434 15.5713 8.5781 15.5829 8.24984 15.5829C4.57746 15.5829 1.8335 14.1312 1.8335 12.8332V12.1018ZM9.82958 11.825C9.31486 11.8855 8.78547 11.9163 8.24978 11.9163C4.57746 11.9164 1.8335 10.4647 1.8335 9.16671V8.43539C2.08353 8.60437 2.35709 8.76407 2.65454 8.91271C4.16437 9.66768 6.15152 10.0834 8.24984 10.0834C9.37879 10.0834 10.4747 9.96202 11.4823 9.7332C10.7957 10.305 10.2301 11.0171 9.82958 11.825ZM8.24978 2.75049C11.922 2.75049 14.6659 4.2022 14.6659 5.5002C14.6659 6.79819 11.922 8.24996 8.24978 8.24996C4.57746 8.24996 1.8335 6.79819 1.8335 5.5002C1.8335 4.2022 4.57746 2.75049 8.24978 2.75049ZM1.8335 16.4997V15.7684C2.08353 15.9374 2.35709 16.097 2.65454 16.2457C4.16437 17.0006 6.15146 17.4164 8.24984 17.4164C8.75589 17.4164 9.25839 17.3916 9.75166 17.3435C10.0313 17.95 10.4027 18.506 10.8483 18.993C10.0313 19.1603 9.14679 19.2494 8.24978 19.2494C4.57746 19.2494 1.8335 17.7976 1.8335 16.4997ZM15.5827 19.2494C13.0553 19.2494 10.999 17.1931 10.999 14.6656C10.999 12.1453 13.0438 10.094 15.5615 10.0824C15.5686 10.0826 15.5755 10.0835 15.5827 10.0835C15.59 10.0835 15.5968 10.0826 15.604 10.0824C18.1217 10.094 20.1665 12.1453 20.1665 14.6656C20.1665 17.1931 18.1102 19.2494 15.5827 19.2494Z"
                      fill="#42BD3B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_881_1912">
                      <rect width="22" height="22" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {data.page.recap.salary}
              </div>
            </div>
          )}
          {fields?.links && (
            <div>
              <Links items={fields.links} />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
